.App {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-color: #282c34;

}

.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0 10px;
}

img {
  height: 400px;
  width: 400px;
  object-fit: cover;
}

button {
  background-color: crimson;
  color: #fff;
  margin-top: 10px;
  padding: 10px 40px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: black;
  margin: 0;
  padding: 0;
  min-height: -webkit-fill-available;
  overflow: hidden;
}

.preview {
  width: 100vw;
  height: 100vh;
  background-color: blue;
}

.button-overlay {
  display: block;
  position: fixed;
  z-index: 999;
  left: 50%;
  bottom: 20px;
  margin: 0 auto;
  transform: translate(-50%, -50%);
}

.react-player {
  display: block;
  position: fixed;
  z-index: 300;
  background-color: #000;
}

/* 
.preview {
  position: absolute;
} */